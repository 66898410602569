/* eslint-disable max-lines */
import { randomUUID } from 'crypto';
import { AppContext } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';

import {
  GiftReturnSettings,
  GiftReturns,
  IGrayFeatureEnabledMap,
  Shops,
} from '@aftership/returns-logics-core';
import { GetStoreConfigPayload, getShopConfig } from '@aftership/returns-logics-core';

import { getReturnPageUrlByUserName } from '@/config/endPoints';
import { AttaProps, getServerSideAttaProps } from '@/features/atta';
import {
  UnifiedDomainGateways,
  getPageDataFromPlatform,
} from '@/features/returns/resources/getPlatform.ts';
import getStores from '@/features/returns/resources/getStores';
import putAppProxyPath from '@/features/returns/resources/tracking.ts';
import {
  getInitialLangByQuery,
  getInitialLng,
  getPersistQuery,
  getReturnsPageAccess,
  getShopifyAppKey,
  insertSecurityHeaders,
  isAppProxy,
  queryToString,
} from '@/features/returns/utils/common';
import { ReturnsPageAccessCode, ReturnsPageAccessStatus } from '@/features/returns/utils/constants';
import {
  SHOPPER_ATTA_EDITOR_KEY,
  SHOPPER_HOST_KEY,
  SHOPPER_PREVIEW_KEY,
  SHOPPER_PROXY_KEY,
} from '@/scripts/custom-server/utils/constants.ts';
import { SSRException, UnexpectedHostnameException } from '@/utils/errorHandler';
import { logger } from '@/utils/logger';

const {
  publicRuntimeConfig: { APP_ENV },
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();

export type CustomShopifyAppProxyConfig = {
  [orgId: string]: {
    headerSelector: string;
  };
};
export const shopifyAppProxyConfig: CustomShopifyAppProxyConfig = {
  '703bf387c72f4fa2b1f7e81f4199905a': {
    headerSelector: '#MainHeader',
  },
  '1c29b3ea3b0b4f0fbc2f3e8e42a4fcd1': {
    headerSelector: "data-section-id='header'",
  },
  '3e10457d249d4df09c1d906b0a83f625': {
    headerSelector: "data-section-id='header'",
  },
};

/**
 * unified 之前, 获取 GetStoreConfigPayload 的逻辑
 * @param isShopifyAppProxy
 * @param shopHostName
 * @param shop
 */
async function getInitialInput(
  isShopifyAppProxy: boolean,
  shopHostName: string,
  shop: string | string[] | undefined,
): Promise<GetStoreConfigPayload> {
  if (!isShopifyAppProxy) {
    return { shopHostName, APP_ENV, AM_API_KEY };
  } else {
    const appKey = getShopifyAppKey(shop as string);
    const res = await getStores(appKey);
    return { orgId: res.connections[0]?.organization.id, APP_ENV, AM_API_KEY };
  }
}

const getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { query } = appContext.ctx;

  const req = appContext.ctx.req as NonNullable<typeof appContext.ctx.req>;
  const res = appContext.ctx.res as NonNullable<typeof appContext.ctx.res>;

  const headersList = req.headers;
  const {
    org_id: orgId,
    path_prefix: pathPrefix,
    proxy_by: queryProxyBy,
    shop,
    hostname,
    lang,
    preview,
    atta_editor,
  } = query;
  const url = req.url || '';

  let shopInfo: Shops | null = {} as Shops;
  let giftReturnSetting: GiftReturns | null = null;
  let appProxy: Returns.AppProxyInfo | null = null;
  let initialLang = 'en-US';
  let grayFeatureEnabledMap: IGrayFeatureEnabledMap | null = null;
  let returnsPageAccess: Returns.ReturnsPageAccessResult | null = null;
  let attaProps: AttaProps | null = null;

  const uaString = headersList['user-agent'] as string;

  const acceptLanguageHeader = headersList['accept-language'] as string;

  let shopHostName = queryToString(
    hostname || (req as any).cookies[SHOPPER_HOST_KEY] || req.headers.host,
  );
  const proxyBy = queryToString(queryProxyBy || (req as any).cookies[SHOPPER_PROXY_KEY]);
  const isPreview = preview === 'true' || (req as any).cookies[SHOPPER_PREVIEW_KEY] === 'true';
  const isAttaEditor =
    atta_editor === 'true' || (req as any).cookies[SHOPPER_ATTA_EDITOR_KEY] === 'true';
  const compatMode = getPersistQuery(appContext.ctx, 'mode');
  // [compat] is for compatibility with old typo issue. For some users who have already used the
  // old typo with iframe, we need to keep the old typo to avoid breaking their existing logic.
  let isCompact = compatMode === 'compact' || compatMode === 'compat' || false;
  // 生成一个 traceId ,用于日志打印
  const traceId = randomUUID();
  const isShopifyAppProxy = isAppProxy(appContext.ctx.res);
  const isInnerAppProxy = Object.values(UnifiedDomainGateways).includes(
    proxyBy as UnifiedDomainGateways,
  );
  try {
    logger.info({
      requestInfo: {
        headers: JSON.stringify(req.headers),
        url: url,
        shopHostName: shopHostName,
        query: {
          proxyBy,
          pathPrefix,
          isInnerAppProxy,
        },
      },
      traceId: traceId,
      type: 'ssr',
    });
    let initialInput: GetStoreConfigPayload = {} as GetStoreConfigPayload;
    if (isInnerAppProxy) {
      if (shopHostName.includes('returnscenter.io') || shopHostName.includes('returnscenter.com')) {
        // 走原有逻辑
        initialInput = await getInitialInput(isShopifyAppProxy, shopHostName, shop);
      } else {
        const platformRes = await getPageDataFromPlatform(shopHostName, traceId);
        initialInput = { orgId: platformRes.organization.id, APP_ENV, AM_API_KEY };
      }
    } else if (orgId) {
      initialInput = { orgId: orgId as string, APP_ENV, AM_API_KEY };
    } else {
      // 走原有逻辑
      initialInput = await getInitialInput(isShopifyAppProxy, shopHostName, shop);
    }

    logger.info({
      initialInput,
      type: 'ssr',
      traceId: traceId,
    });
    const storeConfig = await getShopConfig({ input: initialInput });

    shopInfo = storeConfig?.shopInfo;

    attaProps = await getServerSideAttaProps(
      isPreview,
      isAttaEditor,
      initialInput.AM_API_KEY,
      shopInfo,
    );
    if (isShopifyAppProxy && pathPrefix && shop && shopInfo.organization.id) {
      // 上报当前 org app proxy 模式下的 pathPrefix
      try {
        await putAppProxyPath({
          storeKey: getShopifyAppKey(shop as string),
          orgId: shopInfo.organization.id,
          proxyUrl: `https://${shop}${pathPrefix}`,
        });
      } catch (error) {
        logger.info({
          requestInfo: {
            pathPrefix,
            orgId: shopInfo.organization.id,
          },
          type: 'report-app-proxy-path-error',
          traceId: traceId,
        });
      }
    }

    if (isShopifyAppProxy) {
      appProxy = {
        shopifyProxyMode: true,
        shop: shop as string,
        pathPrefix: pathPrefix as string,
        customizedConfig: shopifyAppProxyConfig[shopInfo.organization.id],
      };
      // Remove header and footer when returns page embedded in Shopify via app proxy
      isCompact = true;
      // 保证 proxy 场景 shopHostName 也是正确的，以便运行 store 现有依赖 shopHostName 的逻辑
      shopHostName = getReturnPageUrlByUserName(shopInfo.organization.short_name);
      // App Proxy: https://shopify.dev/apps/online-store/app-proxies
    } else if (pathPrefix && proxyBy) {
      appProxy = {
        shopifyProxyMode: false,
        shop: shop as string,
        pathPrefix: pathPrefix as string,
      };
    }

    returnsPageAccess = getReturnsPageAccess({
      returnsPageAccess: getPersistQuery(appContext.ctx, 'returns_page_access') || '',
      pathname: url,
      shopInfo,
      isAppProxy: isShopifyAppProxy,
    });

    giftReturnSetting = storeConfig?.giftReturnSetting as GiftReturnSettings;

    grayFeatureEnabledMap = storeConfig?.grayFeatureEnabledMap as IGrayFeatureEnabledMap;

    if (getPersistQuery(appContext.ctx, 'lang')) {
      initialLang = getInitialLangByQuery(shopInfo, queryToString(lang));
    } else {
      // in preview mode, ignore the locale
      initialLang = isPreview
        ? shopInfo.default_language
        : getInitialLng(acceptLanguageHeader, shopInfo);
    }

    insertSecurityHeaders(res, shopInfo, process.env.NODE_ENV === 'development');

    return {
      appProps,
      initialProps: {
        shopInfo,
        giftReturnSetting,
        shopHostName,
        isCompact,
        appProxy,
        uaString,
        initialLang,
        grayFeatureEnabledMap,
        returnsPageAccess,
        isAppProxy: isShopifyAppProxy,
        APP_ENV,
        attaProps,
        isPreview,
      },
    };
  } catch (error: any) {
    logger.info('shopInfo', error);
    if (
      error instanceof UnexpectedHostnameException ||
      error.code === 404 ||
      // shops 接口迁移到 v4, code 会由 404 变为 404xx,所以这里除以 100
      Math.floor((error.code ?? 0) / 100) === 404
    ) {
      res.statusCode = 404;
      returnsPageAccess = {
        code: ReturnsPageAccessCode.UnexpectedHostname,
        status: ReturnsPageAccessStatus.Denied,
      };
    } else {
      res.statusCode = 500;
      logger.error({
        error: new SSRException(error.message, { cause: error }),
        headers: {
          path: url,
          ...req.headers,
        },
        traceId: traceId,
      });
      returnsPageAccess = {
        code: ReturnsPageAccessCode.ServerError,
        status: ReturnsPageAccessStatus.Denied,
      };
    }

    insertSecurityHeaders(res, shopInfo, APP_ENV === 'development');

    // 出错后，需要初始化 i8n
    initialLang = getInitialLng(acceptLanguageHeader, shopInfo);

    return {
      appProps,
      initialProps: {
        shopInfo,
        giftReturnSetting,
        shopHostName,
        isCompact,
        appProxy,
        uaString,
        initialLang,
        grayFeatureEnabledMap,
        returnsPageAccess,
        isAppProxy: isShopifyAppProxy,
        APP_ENV,
        attaProps,
        isPreview,
      },
    };
  }
};

export default getInitialProps;
